import React ,{ createContext, ReactNode, useContext, useRef, useState } from "react";
import { Registerer, Session, UserAgent } from "sip.js";


export interface ChatContextStateData {
    accessToken: any;
    callToken: any;
    customerID:String;
    JwtToken:string;
    isDeleted:boolean;
    threadId:string;
    userAgentRef: UserAgent | null;
  registererRef: Registerer | null;
  sessionRe: Session | null | any;
  extension: {
    Uri: string;
    UA: string;
    Passcode: string;
  };
  };

export const ChatContextState: ChatContextStateData = {
    accessToken:null,
    callToken: null,
    customerID:'',
    JwtToken:'',
    isDeleted:false,
    threadId:'',
    userAgentRef: null,
  registererRef: null,
  sessionRe: null,
  extension: {
    Uri: "",
    UA: "",
    Passcode: "",
  },
  };

  export interface ChatContextActionData {
    setAccessToken: (type:any)=> void,
    setCallToken: (type:any)=> void,
    setCustomerID: (type:string)=> void,
    setJwtToken: (type:string)=> void
    setIsDeleted: (type:boolean)=> void;
    setThreadId: (type:string)=> void;
    setExtension: (extension: Partial<{ Uri: string; UA: string; Passcode: string }>) => void;
  };

export const ChatContextAction: ChatContextActionData = {
    setAccessToken: () => {},
    setCallToken: ()=> {},
    setCustomerID: () => {},
    setJwtToken: () => {},
    setIsDeleted: () => {},
    setThreadId: () => {},
    setExtension: () => {},
  };

export const ChatViewContext = createContext(ChatContextState)
export const ChatViewActionContext = createContext(ChatContextAction)

export const ChatViewContextInfo = () => useContext(ChatViewContext)
export const ChatViewActionContextInfo = () => useContext(ChatViewActionContext)
interface ChatContextProviderProps {
  children: ReactNode;
}

const ChatContextprovider: React.FC<ChatContextProviderProps>= ({ children }) => {
  const [accessToken,setAccessToken] = useState<any>('');
  const [callToken,setCallToken] = useState<any>('');
  const [customerID, setCustomerID] = useState<string>('')
  const [JwtToken, setJwtToken] = useState<string>('')
  const [isDeleted, setIsDeleted] = useState<boolean>(false)
  const [threadId, setThreadId] = useState('')
  const userAgentRef = useRef<UserAgent | null>(null);
  const registererRef = useRef<Registerer | null>(null);
  const sessionRe = useRef<Session | null | any>(null);
  const [extension, setExtensionState] = useState<{ Uri: string; UA: string; Passcode: string }>({
    Uri: "",
    UA: "",
    Passcode: "",
  });
  // Function to update the extension object
  const setExtension = (newExtension: Partial<{ Uri: string; UA: string; Passcode: string }>) => {
    setExtensionState((prev) => ({ ...prev, ...newExtension }));
  };
  const contextValue = {
    accessToken,
    customerID,
    JwtToken,
    isDeleted,
    threadId,
    userAgentRef: userAgentRef.current,
    registererRef: registererRef.current,
    sessionRe: sessionRe.current,
    extension,
    callToken
  };

    return(
   
        <ChatViewContext.Provider value={contextValue}>
          <ChatViewActionContext.Provider value={
            {
              setAccessToken,
              setCustomerID,
              setJwtToken,
              setIsDeleted,
              setThreadId,
              setExtension,
              setCallToken
            }
          }>
            {children}
          </ChatViewActionContext.Provider>
        </ChatViewContext.Provider>
    )
}

export default ChatContextprovider;